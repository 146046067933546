@import '../../styles/brand-colors';

.admin-utility-row {
  display: flex;
  width: 100%;
  padding: 1rem;

  .admin-controls-group {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;

    .admin-actions {
      display: flex;
      gap: 0.5rem;
      margin-left: auto; // Push actions to the right
    }
  }
}

.admin-select {
  width: 200px;
}

.admin-input-group {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;

  .admin-input {
    width: 350px;
  }

  .admin-action-input {
    width: 275px;
  }
}

.admin-list {
  min-height: 200px;
  padding: 1rem;

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

.admin-list-table {
  border: 1px solid $midnight-30;
  border-radius: 4px;
  overflow: scroll;
  width: 1000px;
  height: 580px;

  .virtualized-table-standard {
    height: 515px;
  }
}

.admin-option-table {
  background-color: white;
  border-radius: 4px;
  
  .MuiTableHead-root {
    .MuiTableCell-head {
      background-color: #f5f5f5;
      font-weight: bold;
      padding: 8px 16px;
      height: 48px;
    }
  }

  .MuiTableRow-root {
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  .MuiTableCell-root {
    padding: 4px 16px;
    height: 40px;

    &:focus {
      outline: none;
    }
  }

  [title] {
    cursor: help;
  }
}

.admin-loading {
  border: 1px solid $midnight-30;
  border-radius: 4px;
  min-height: 460px;
}

.success {
  background-color: green;

  path {
    fill: white !important;
  }
}

.warning {
  background-color: goldenrod;
  path {
    fill: white !important;
  }
}

.danger {
  background-color: darkred;
  path {
    fill: white !important;
  }
}

.dialog-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem 0;

    .form-group {
        display: flex;
        align-items: center;
        
        .MuiFormControl-root {
            width: 100%;
        }

        .MuiFormControlLabel-root {
            margin-left: 0;
        }
    }
}