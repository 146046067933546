@import '../../styles/brand-colors';

.bulk-panel {
    position: absolute;
    float: right;
    background-color: $white;
    border-left: 1px solid $dusk-thirty;
    border-right: 1px solid $dusk-thirty;
    box-shadow: -2px 1px 3px $dusk-thirty;
    border-top: 1px solid $dusk-thirty;
    border-radius: 0 3px 0 0;
    display: flex;
    flex-direction: column;
    top: 0;
    right: 0;
    height: 275px;
    min-width: 17em;
    width: 450px;
    z-index: 200;
}

.bulk-panel-title {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $dusk-thirty;
    padding: 12px;
    margin-bottom: 16px;
    & svg {
        > path {
            fill: $dusk-thirty;
        }
    }

    & span {
        width: 100%;
        text-align: center;
        color: $dusk-light;
        font-weight: bold;
        font-size: 16px;
    }
}
.bulk-panel-options {
    flex-grow: 1;
}

.upload-select {
    width: 200px;
}

.bulk-panel-step {
    display: flex;
    height: 60px;
    justify-content: space-between;
    //flex-direction: column;

    label {
        margin: 0;
        padding: 0 0 0 16px;
        color: $dusk-lighter;
        font-size: 16px;
    }
}

.step-separator {
    border-bottom: 1px solid $dusk-thirty;
    left: 0;
    right: 0;
    margin: 16px;
}
