@import '../../styles/brand-colors';

.home {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .lower-env-banner {
    display: flex;
    position: absolute;
    top: 400px;
    left: 300px;
    opacity: 0.25;
    z-index: 999;
  }

  .layout-container {
    display: flex;
    flex: 1;
    position: relative;
    overflow: hidden;
  }

  .menu-button {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1100;
  }

  .sidebar {
    width: 240px;
    flex-shrink: 0;
    transition: width 0.3s ease;

    &.minimized {
      width: 64px;

      .sidebar-item-label {
        opacity: 0;
        width: 0;
        margin-left: 0;
      }

      .sidebar-item {
        padding: 12px;
        justify-content: center;
      }

      .minimize-button {
        right: 12px;
      }
    }

    &:hover {
      .sidebar-item-label {
        opacity: 1;
      }
    }

    .sidebar-paper {
      width: inherit;
      background-color: #f5f5f5;
      border-right: 1px solid rgba(0, 0, 0, 0.12);
      transition: width 0.3s ease;
      overflow-x: hidden;
      height: calc(100vh - 40px);
    }
  }

  .sidebar-content {
    padding: 20px 0;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .minimize-button {
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 1;
    transition: right 0.3s ease;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  .sidebar-item {
    padding: 12px 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 4px;
    margin: 4px 8px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }

    &.selected {
      background-color: #1976d2;
      color: white;
      font-weight: 500;
      position: relative;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      .sidebar-item-icon {
        svg {
          stroke: white;
        }
      }

      &:hover {
        background-color: #1565c0;
      }
    }

    .sidebar-item-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      flex-shrink: 0;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .sidebar-item-label {
      margin-left: 12px;
      white-space: nowrap;
      opacity: 1;
      transition: opacity 0.3s ease, margin-left 0.3s ease, width 0.3s ease;
    }
  }

  .main-content {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
    background-color: #ffffff;

    .content-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);

        .content-title {
            display: flex;
            align-items: center;
            gap: 12px;
            margin: 0;
            font-size: 28px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.87);

            svg {
                color: #1976d2;
                stroke-width: 2px;
            }

            span {
                font-weight: 500;
            }
        }

        .action-buttons {
            display: flex;
            gap: 8px;
            flex-wrap: wrap;
        }
    }

    .content-title {
        display: flex;
        align-items: center;
        gap: 12px;
        margin: 0;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.87);

        svg {
            color: inherit;
        }
    }

    .action-buttons {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
    }
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-bottom: 16px;
    flex-wrap: wrap;

    button {
      white-space: nowrap;
    }
  }

  footer {
    padding: 8px 16px;
    background-color: #f5f5f5;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    gap: 8px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;

    svg {
      cursor: pointer;

      &.spinning-icon {
        animation: spin 1s linear infinite;
      }
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @media (max-width: 1024px) {
    .layout-container {
      padding-top: 48px;
    }

    .sidebar {
      position: absolute;
      height: calc(100vh - 40px);
      z-index: 1200;

      .sidebar-paper {
        box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
      }
    }

    .action-buttons {
      justify-content: flex-start;
    }

    .main-content {
        .content-header {
            flex-direction: column;
            align-items: flex-start;
        }
    }
  }

  .sidebar-trigger {
    position: fixed;
    left: 0;
    top: 0;
    width: 16px;
    height: 100vh;
    z-index: 1199;
  }
}