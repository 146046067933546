@import '../../styles/brand-colors';

.modal-module_small__3PkJI {
  height: 550px !important;
  width: 750px !important;
}

.ua-properties {
  padding-top: 5px;
  height: 500px;
  overflow: scroll;
  
  .MuiCircularProgress-root {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.ua-input-group {
  padding-bottom: 3px;
  display: flex;
  align-items: center;
  gap: 8px;

  label {
    width: 150px;
  }

  .number-input {
    width: 370px;
  }

  .MuiButton-root {
    min-width: 90px;
    height: 36px;
    flex-shrink: 0;
    padding: 6px 16px;

    &.link-button {
      min-width: 210px;
      justify-content: flex-start;
      padding-left: 0;
      text-transform: none;
    }
  }

  .MuiSelect-root, .MuiTextField-root {
    .MuiSelect-select, .MuiInputBase-input {
      padding: 8px 12px;
      height: 20px;
    }
  }
}

.missing-data-warning {
  padding: 5px;
  margin: 20px;
  border-radius: 10px;
  display: flex;
  background-color: darkred;
  p {
    margin: 3px;
  }
}

.unit-info {
  width: 730px;
  .unit-key {
    width: 150px;
    font-weight: 700;
  }
  .unit-value {
    width: 400px;
  }
  .select-unit-button {
    width: 120px;
    
    .MuiButton-root {
      min-width: 100px;
      white-space: nowrap;
    }
  }
  .select-cohort-button {
    width: 100px;
  }
}

.cohort-match {
  border-style: solid;
  border-radius: 3px;
  border-color: darkgreen;
}

.cohort-mismatch {
  border-style: solid;
  border-radius: 3px;
  border-color: darkred;
}