@import '../../../styles/brand-colors';

.virtualized-table {
  width: 100%;
  padding-bottom: 14px;
}

.virtualized-table-standard {
  height: 600px;
}

.virtualized-table-short {
  height: 300px;
}

.virtualized-table-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 700px;
  width: 100%;
}

.virtualized-table-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  color: $dusk-light;
  font-size: 15px;
  text-transform: capitalize;
  text-align: center !important;
  font-weight: bold;
  margin-bottom: 14px;
  border: 1px;

  max-width: 100%;
  min-width: 100%;
  width: 0;

  &.filtered-header {
    justify-content: flex-end;
    text-align: center;

    input {
      border: 1px solid $dusk-lightest;
      max-width: 100%;
      padding: 6px;
      border-radius: 4px;
      min-width: 100%;
      width: 0;

      &:focus {
        outline: $midnight-30;
        border: 2px solid $midnight-30;
        height: 100%;
        max-height: 22px;
      }
    }

    .number-input {
      width: 0;
      min-width: 45% // range inputs -- min/max are side-by-side
    }

    .filtered-header-select {
      .MuiSelect-select,
      .MuiInputBase-input {
        border: 1px solid $dusk-lightest !important;
        height: 15px;
      }
    }

    .filtered-header-input {
      width: 100%;
      height: 24px;
      padding: 6px;
      border-radius: 4px;
      border: 1px solid $dusk-lightest;
      margin-left: -10px;
    }
  }

  &.filtered-action-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    // color: $dusk-thirty;
    font-size: 14px;
  }
}

.virtualized-table-column {
  border-bottom: 1px solid $midnight-40;
  color: $dusk-lighter;
  span .circle {
    width: 11px !important;
    min-width: 11px;
    height: 11px !important;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    margin-right: 2px;
    margin-bottom: 2px;
    &.green {
      background: #6ba342;
    }
    &.red {
      background: #d62e4f;
    }
    &.yellow {
      background: #ffd00a;
    }
    &.midnight {
      background: #1a475b;
    }
  }
}

.decrease-font-size {
  font-size: 13px !important;
}

.very-decreased-font-size {
  font-size: 11px !important;
}

.virtualized-table-cell-div {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

.virtualized-table-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  height: 50px;

  > svg {
    & path {
      fill: $dusk-lighter;
    }
  }
}

.ReactVirtualized__Grid,
.ReactVirtualized__Table__Grid,
.ReactVirtualized__Grid__innerScrollContainer {
  &:focus {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
    outline: none;
  }
}

.ReactVirtualized__Table__headerRow {
  background-color: $white;
  padding-top: 7px;
  border-bottom: 2px solid $dusk-thirty;
  padding-right: 0 !important;
  padding-bottom: 7px !important;
  display: flex;
}

.ReactVirtualized__Table__row {
  border-bottom: 1px solid $midnight-20;
  justify-content: space-evenly !important;
  display: flex;
}

.ReactVirtualized__Table__headerColumn {
  height: 100%;
  display: flex;
  justify-content: center;
  padding-left: 20px !important;
  width: 0;
}

.ReactVirtualized__Table__rowColumn {
  display: block;
  flex: none;
  padding: 0;
}

.RowColumnUnitCode {
  display: block;
  flex: none;
  padding: 0;
  white-space: normal !important;
}

.virtualized-table-checkbox {
  color: $midnight-40 !important;

  &:hover {
    background-color: $midnight-0 !important;
  }
  &.MuiCheckbox-colorSecondary.Mui-checked {
    color: $lake !important;
  }

  &.Mui-disabled {
    opacity: 0.5;
  }
}

.range-filter {
  color: $dusk-thirty !important;
  width: 100% !important;

  input {
    width: 40% !important;
    padding: 6px 1px !important;
    text-align: center;
    font-size: 14px;

    &:focus {
      height: 23px !important;
    }
  }
  input::-webkit-input-placeholder {
    font-size: 12px;
  }

  input.min {
    border-radius: 4px 0 0 4px !important;
    border-right: none;
  }

  input.max {
    border-radius: 0 4px 4px 0 !important;
  }

  input.error {
    border-color: $danger !important;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  ::-webkit-input-placeholder {
    text-align: center;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    text-align: center;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    text-align: center;
  }

  :-ms-input-placeholder {
    text-align: center;
  }
}

.input-disabled {
  opacity: 0.3;
}

.add-margin {
  margin-left: 4px;
}

.vacasa-modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    border-bottom: 1px solid $dusk-lightest;
    padding-left: 16px;
    padding-right: 16px;

    p {
      color: #707579;
      font-size: 18px;
      font-weight: bold;
    }

    svg > path {
      fill: #bfc2c3;
    }
}

.modal-module_medium__3xb3r{
  padding: 32px !important;

  .modal-module_modalClose__pvct8{
    visibility: hidden;
  }
}
