
.cohort-info {
  width: 500px;
}

.admin-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 370px;
}