@import '../../styles/brand-colors';

.rates-input-row {
    display: flex;
    width: 100%;
    padding-top: 20px;

    .rates-input-group {
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
        padding: 10px 20px;

        .rates-select {
            width: 300px;
        }
        .rates-multi-select {
            width: 500px;
        }
    }
}

.menu-selector-right {
    display: flex;
    width: max-content;
    float: right;
    justify-content: flex-end;
    align-items: flex-end;
    margin-right: 14px;
    margin-bottom: 14px;
}

.chart-visibility-toggle {
    font-size: 16px;
    color: $gray-one;
    font-style: normal;
    font-weight: bolder;
    line-height: 1;
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.div-chart {
    height: 350px;
}

.chart-visibility-toggle-icon {
    stroke-width: 3;

    & > path {
        fill: $dusk;
    }

    margin-right: 4px;
}
.div-alert {
    display: flex;
    margin-top: 16px;
    margin-bottom: -16px;
    height: 62px;
}
.dynamic-tabs {
    position: relative;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    .MuiTab-labelIcon {
        min-height: 48px;
    }
}

.save-button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
}

div[class^='tabs-module_filled_tab_body'] {
    border-radius: 0 4px 4px 4px;
    padding-top: 8px !important;
}

.icon-tabs {
    position: absolute;
    right: 5px;
    top: 5px;
    transform: rotateY(0deg) rotate(0deg);
    > path {
        fill: $dusk-thirty;
    }
}

.active-icon {
    transform: rotateY(0deg) rotate(-45deg);
    > path {
        fill: $midnight-90;
    }
}

.title-container {
    display: flex;
    height: 26px;

    > p {
        margin: auto;
        color: $midnight-70;
    }

    .input-container {
        display: flex;
        border: 1px solid $midnight-20;
        border-radius: 4px;

        > input {
            width: 100px;
            border: none;
            text-align: left;
        }

        .icon-container {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $midnight-20;
            margin: 0 auto;
            width: 36px;
            min-width: 36px;

            .loading-new-unit {
                height: 24px;
                width: 36px;

                .loader-dots {
                    top: 0 !important;
                    left: 0 !important;
                }
            }

            .disabled {
                pointer-events: none;

                > path {
                    fill: $dusk-ten;
                }
            }
        }
    }
}

.ab-testing-message {
    background-color: #335c6d !important;
    color: #ffffff !important;
    border-color: #335c6d !important;

    svg {
        path {
            fill: #ffffff;
        }
    }
}
