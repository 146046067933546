@import '../../styles/brand-colors';
@import '../../App.scss';

.rebl-body {
    height:550px;
    min-height: 0;
    padding: 16px 32px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;

    @media (max-width: $laptop-breakpoint) {
        height: calc(100vh - 120px);  // Slightly more space for smaller screens
        max-height: 550px;
        padding: 5px 15px;
    }

    svg {
        margin: unset !important;;
    }

    .rebl-row {
        display: flex;
        gap: 16px;
        flex: 1;
        min-height: 0;
        overflow: hidden;
    }
}

.selected {
    font-weight: bold;
    text-decoration: underline;
}

.section-row {
    font-size: 14px;
    padding-left: 20px;
}
.rebl-col {
    display: flex;
    flex-direction: column;
}

.accordion-col {
    width: 300px;
}

.rebl-accordion-row {
    display: flex;
}

.rule-col {
    width: 700px;
}

.component-row {
    font-size: 12px;
    .small-cb {
        height: 8px;
        svg {
            height: 16px;
            width: 16px;
        }
    }
}

.cb-row {
    height: 30px;
}

.spacer {
    width: 20px;
}

.rebl-label-decreased-font {
    font-size: 14px;
}

.rebl-auto-complete {
    width: 450px;
}

.rebl-single-complete {
    width: 400px;
    input {
        font-size: 12px;
        padding: 1px !important;
    }
}

.validation-messages {
    padding: 8px;
    background-color: #f8f9fa;
    border-radius: 4px;
    max-height: 60px;
    overflow-y: auto;
    font-size: 0.85rem;

    .validation-group {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        margin-bottom: 4px;

        &:last-child {
            margin-bottom: 0;
        }

        .messages {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;

            .message {
                display: flex;
                align-items: center;
                gap: 4px;
                padding: 2px 8px;
                border-radius: 2px;
                white-space: nowrap;
                
                &.error {
                    color: #dc3545;
                    background-color: rgba(220, 53, 69, 0.1);
                }

                &.warning {
                    color: #856404;
                    background-color: rgba(255, 193, 7, 0.1);
                }

                .MuiSvgIcon-root {
                    font-size: 16px;
                }
            }
        }
    }
}

.rebl-field {
    position: relative;
    width: 100%;
    padding: 8px 0;

    @media (max-width: $laptop-breakpoint) {
        padding: 0;
    }

    &.has-error {
        border-left: 3px solid #dc3545;
        padding-left: 8px;
    }
    
    &.has-warning {
        border-left: 3px solid #ffc107;
        padding-left: 8px;
    }

    .MuiSelect-root,
    .MuiTextField-root,
    .MuiAutocomplete-root,
    .MuiFormControl-root {
        width: 450px;
    }

    // Make included units field resizable
    &.rebl-textarea {
        width: 450px;
        font-family: inherit;
        font-size: 14px;
        resize: vertical;
    }

    // Helper text styles
    .MuiFormHelperText-root {
        width: 450px;
        white-space: normal;
        word-break: normal;
        margin-top: 4px;
        font-size: 12px;
        line-height: 1.4;
    }

    .MuiOutlinedInput-root {
        padding: 0;
        height: 40px;
        width: 450px;

        .MuiOutlinedInput-input {
            padding: 8px 14px;
            height: 24px;
        }

        .MuiSelect-select {
            padding: 8px 14px;
            height: 24px;
        }

        &.MuiInputBase-multiline {
            height: auto;
        }
    }

    .MuiTextField-root.rebl-day-number-input {
        width: 300px;
    }

    .MuiPickersInlineKeyboardInput-input {
        padding: 8px 14px !important;
        height: 24px !important;
    }

    .MuiCheckbox-root {
        padding: 8px;
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
    }

    .date-range-group {
        width: 450px !important;
        display: flex;
        align-items: center;
        gap: 12px;

        .MuiTextField-root {
            width: 210px !important;
            min-width: 0;

            .MuiOutlinedInput-root {
                width: 210px !important;
                
                &.Mui-focused .MuiOutlinedInput-notchedOutline {
                    border-color: rgba(0, 0, 0, 0.23);
                    border-width: 1px;
                }
            }
        }
    }

    textarea.MuiOutlinedInput-input {
        padding: 8px 14px !important;
        width: 450px !important;
        height: auto !important;
        resize: vertical;
        min-height: 100px;
        max-height: 400px;
    }

    &.has-error input,
    &.has-error select,
    &.has-error textarea {
        border-color: #dc3545;
        border-width: 1px;

        &:focus {
            box-shadow: none;
            border-color: #dc3545;
        }
    }

    &.has-warning input,
    &.has-warning select,
    &.has-warning textarea {
        border-color: #ffc107;
        border-width: 1px;

        &:focus {
            box-shadow: none;
            border-color: #ffc107;
        }
    }

    .field-validation-messages {
        position: absolute;
        top: 50%;
        left: calc(100% + 8px);
        transform: translateY(-50%);
        z-index: 1;
        min-width: 200px;
        max-width: 300px;

        .field-message {
            font-size: 12px;
            padding: 4px 8px;
            margin-bottom: 1px;
            background-color: rgba(255, 255, 255, 0.98);
            border-radius: 2px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
            white-space: normal;

            &.error {
                color: #dc3545;
                border-left: 2px solid #dc3545;
            }

            &.warning {
                color: #ffc107;
                border-left: 2px solid #ffc107;
            }
        }
    }
}

.button-group {
    margin: 0 4px;
}

.rebl-sidebar {
    max-height: calc(700px - 40px - 76px - 45px - 32px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 350px;
    margin-right: 16px;
    flex-shrink: 0;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;

    @media (max-width: $laptop-breakpoint) {
        max-height: calc(100vh - 120px);  // Adjust for smaller screens
    }

    .sidebar-content {
        flex: 1;
        overflow-y: auto;
        overflow-x: hidden;

        .MuiCollapse-container {
            max-height: 100%;
        }
    }

    .sidebar-header,
    .search-field {
        flex-shrink: 0;
    }

    .rule-category {
        .category-header {
            padding: 8px 16px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.87);
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;
            background-color: #f5f5f5;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);

            &:hover {
                background-color: #e0e0e0;
            }

            .MuiSvgIcon-root {
                font-size: 18px;
                color: rgba(0, 0, 0, 0.54);
            }

            span {
                flex: 1;
            }
        }

        .rule-section {
            margin-left: 4px;
            border-left: 1px solid rgba(0, 0, 0, 0.08);

            .section-header {
                padding: 6px 16px 6px 20px;
                display: flex;
                align-items: center;
                gap: 8px;
                cursor: pointer;
                font-size: 0.95rem;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.04);
                }

                .MuiSvgIcon-root {
                    font-size: 18px;
                }
            }

            .rule-item {
                padding: 4px 016px 4px 10px;
                display: flex;
                align-items: center;
                gap: 8px;
                font-size: 0.9rem;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.04);
                }

                &.selected {
                    background-color: rgba(25, 118, 210, 0.08);
                }

                .MuiCheckbox-root {
                    padding: 4px;
                }

                .MuiSvgIcon-root {
                    font-size: 16px;
                    color: rgba(0, 0, 0, 0.54);
                }
            }
        }
    }
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 1;

    .MuiCircularProgress-root {
        color: #1976d2;
    }

    .MuiTypography-body1 {
        margin-top: 16px;
        color: rgba(0, 0, 0, 0.87);
    }
}

.sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    background-color: #f5f5f5;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    .MuiTypography-h6 {
        font-size: 1.1rem;
        font-weight: 500;
    }

    .sidebar-actions {
        .toggle-all-button {
            color: rgba(0, 0, 0, 0.54);
            
            &:hover {
                color: rgba(0, 0, 0, 0.87);
                background-color: rgba(0, 0, 0, 0.04);
            }
        }
    }
}

.search-field {
    padding: 8px 16px;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);

    .MuiInputBase-root {
        font-size: 14px;
    }

    .clear-button {
        padding: 4px;
        color: rgba(0, 0, 0, 0.54);
        
        &:hover {
            color: rgba(0, 0, 0, 0.87);
            background-color: rgba(0, 0, 0, 0.04);
        }
    }
}

.rebl-form {
    flex: 1;
    min-width: 600px;
    width: 0;
    overflow-y: auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .form-section {
        flex-shrink: 0;
        background-color: #fff;
        padding: 10px !important;
        border-radius: 4px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);

        .MuiTypography-h6 {
            font-size: 1.1rem;
            font-weight: 500;
            color: #1976d2;
            padding-bottom: 12px;
            margin-bottom: 16px;
            border-bottom: 2px solid #1976d2;
        }

        .rule-section {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }

            .MuiTypography-subtitle1 {
                font-size: 0.95rem;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.87);
                margin-bottom: 12px;
                background-color: #f5f5f5;
                padding: 8px 0 8px 12px;
                border-left: 4px solid #1976d2;
                border-radius: 2px;
            }
        }

        @media (max-width: $laptop-breakpoint) {
            padding: 5px !important;
        }
    }
}

.date-range-group {
    width: 450px !important;
    display: flex;
    align-items: center;
    gap: 12px;

    .MuiTextField-root {
        width: 210px !important;
        min-width: 0;

        .MuiOutlinedInput-root {
            width: 210px !important;
            
            .MuiOutlinedInput-input {
                width: 210px !important;
            }
        }
    }

    span {
        color: rgba(0, 0, 0, 0.6);
        padding: 0 4px;
        flex-shrink: 0;
    }
}

textarea.MuiOutlinedInput-input {
    padding: 8px 14px !important;
    min-height: 80px;
    width: 450px !important;
}

.rebl-input-group {
    display: grid;
    grid-template-columns: 200px minmax(300px, 1fr);
    gap: 24px;
    align-items: center;
    margin-bottom: 8px;
    position: relative;

    // Label container
    .label-container {
        display: flex;
        justify-content: flex-end;
        min-height: 40px;
        padding: 0;
        align-items: center;

        .required-indicator {
            color: #dc3545;
            margin-right: 4px;
        }

        label {
            text-align: right;
            color: rgba(0, 0, 0, 0.87);
            font-size: 14px;
            display: flex;
            align-items: center;
            font-weight: 500;
            word-break: break-word;
            white-space: normal;
            line-height: 1.2;
            
            .MuiSvgIcon-root {
                margin-left: 4px;
                font-size: 16px;
                color: rgba(0, 0, 0, 0.54);
            }
        }
    }

    // Field container
    .rebl-field {
        min-width: 0;
        display: flex;
        align-items: center;

        // Common styles for all form controls
        .MuiFormControl-root,
        .MuiAutocomplete-root,
        .MuiSelect-root {
            width: 300px;
        }

        // Checkbox specific alignment
        .MuiCheckbox-root {
            padding: 8px;
            margin: 0;
        }

        // Date range specific styles
        .date-range-group {
            width: 450px !important;

            .MuiTextField-root {
                width: 210px !important;

                .MuiOutlinedInput-root {
                    width: 210px !important;
                }
            }
        }

        // Number range specific styles
        .number-range-group {
            display: flex;
            align-items: center;
            gap: 12px;
            width: 300px;

            .MuiTextField-root {
                width: 140px;
            }

            span {
                color: rgba(0, 0, 0, 0.6);
                padding: 0 4px;
            }
        }

        // Textarea specific styles
        textarea.MuiOutlinedInput-input {
            min-height: 80px;
            width: 300px;
        }

        // Update spacing for selected items in GeographySelector
        .selected-items {
            margin-top: 4px;
            display: flex;
            flex-wrap: wrap;
            gap: 4px;
        }
    }

    // Remove rule button
    .remove-rule {
        position: absolute;
        top: 10px;
        opacity: 0.7;
        
        &:hover {
            opacity: 1;
        }
    }

    @media (max-width: $laptop-breakpoint) {
        gap: 16px;

        .label-container {
            label {
                font-size: 12px;
            }
        }
    }
}

// Update input heights and padding for consistency
.MuiOutlinedInput-input {
    padding: 8px 14px !important;
    height: 24px !important;
}

// Ensure consistent heights for selects
.MuiSelect-select.MuiSelect-outlined {
    padding: 8px 14px !important;
    height: 24px !important;
}

// Ensure Autocomplete has consistent height
.MuiAutocomplete-inputRoot {
    padding: 0 !important;
    height: 40px !important;

    .MuiAutocomplete-input {
        padding: 8px 14px !important;
        height: 24px !important;
    }
}

.form-footer {
    height: 40px;
    flex-shrink: 0;
    padding: 10px 0;
    background-color: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;

    // Left column for validation messages
    .validation-container {
        flex: 1;
        min-width: 0; // Allow shrinking
        max-width: calc(100% - 190px); // Leave space for buttons
    }

    // Right column for buttons
    .form-footer-buttons {
        flex-shrink: 0;
        padding-top: 30px;
        display: flex;
        justify-content: flex-end;
        gap: 8px;
        width: 190px;

        @media (max-width: $laptop-breakpoint) {
            gap: 5px;
            padding-top: 0px;
        }
    }
}

// Update the validation messages for laptop screens
.validation-messages {
    @media (max-width: $laptop-breakpoint) {
        font-size: 10px;
        padding: 5px;
        max-height: 50px;

        .validation-group {
            gap: 6px;
            margin-bottom: 3px;

            .message {
                padding: 1px 6px;
                
                .MuiSvgIcon-root {
                    font-size: 14px;
                }
            }
        }
    }
}

// Add styles for the form header
.form-header {
    position: sticky;
    top: 0;
    background: white;
    z-index: 10;
    padding: 8px 0;
    margin-bottom: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    @media (max-width: $laptop-breakpoint) {
        padding: 6px 0;
        margin-bottom: 6px;
    }
}
