@import 'styles/brand-colors';

.App {}

$laptop-breakpoint: 1600px; // Point where screensize much smaller than a monitor

@media (max-width: $laptop-breakpoint) {
  body {
    font-size: 14px;
  }

  .MuiTableCell-root.table-cell {
    padding: 5px !important;
  }

  .MuiChip-root.chip {
    height: 20px !important;
    font-size: 10px !important;
  }

  .MuiButton-root {
    padding: 6px 10px;
    font-size: 12px;
  }

  .MuiInputBase-root {
    font-size: 12px;
  }

  .MuiFormLabel-root {
    font-size: 12px;
  }
}

@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}

.spinning-icon {
  animation: spin infinite 3s linear;
}

svg {
  margin: 3px;
}

.MuiCheckbox-root {
  padding: 0 15px !important;
}

textarea {
  word-break: break-word;
  border-width: 1px;
  border-color: #2c3439;
}

input {
  font-size: 12px;
  border-width: 1px;
  border-color: #2c3439;
  border-radius: 5px;
}

.action-button-list {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;

  .action-button {
    height: 30px;
    border: 1px solid $midnight-40;
    border-radius: 4px;
    padding: 4px;
    margin: 0 4px;
    cursor: pointer;
  }
  .action-button-text {
    min-width: 110px;
    display: flex;
    text-align: center;
  }
  .action-button-icon {
    width: 30px;
  }
}

.MuiAccordionSummary-root.Mui-expanded {
  height: 50px;
  max-height: 50px;
  min-height: 50px !important;
  background: #E5EBED;

  .imagen-collapse {
    transform: rotate(90deg)
  }

  .add-date-button {
    visibility: visible;
  }
}

.decreased-font {
  text-align: right;
  font-size: 13px
}

.very-decreased-font {
  text-align: right;
  font-size: 11px
}

.accordion-row {
  width: 100%;
  // height: unset !important;
  // padding: 0 !important;

  .accordion-summary {
    height: 50px;
    padding: 0;

    & .MuiAccordionSummary-content {
      border-bottom: 1px solid $midnight-40;
    }
  }

  .accordion-details {
    width: 100%;
    border-collapse: collapse;
    margin-top: -8px;
    margin-left: -15px;
    padding-bottom: 0;

    .table {
      width: 100%;
      background: #F5F7F8;
      border-collapse: collapse;

      .table-header {
        width: 100%;
        height: 24px;
        left: 914px;
        top: 271px;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 45px;
        border-bottom: 1px solid $midnight-40;
      }

      .table-body {
        text-align: center;
        width: 100%;
        border-bottom: 1px solid $midnight-40;
        height: 35px;
        left: 1338px;
        top: 393px;
        font-family: Helvetica;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 45px;
      }
    }
  }

  & .MuiAccordion-rounded:last-child {
    border-radius: 0;
  }

}

.ReactVirtualized__Table__headerRow {
  width: 15px;
  height: 24px;
  left: 105px;
  top: 366px;
}

.icon-container {
  padding-right: 15px;
}

.disabled-icon {
  pointer-events: none;

  > path {
    fill: #c0c0c0;
  }
}

.iconsDiv {
  .pointer {
    cursor: pointer;
  }

  .editIcon {
    margin-right: 5px;
  }
}

// Add styles for sticky table header cells
.sticky-header-cell {
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 3;
  padding: 16px;
  margin: 0;
  border-bottom: 2px solid rgba(224, 224, 224, 1);

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: 2px;
    background-color: rgba(224, 224, 224, 1);
  }
}

// Add styles for the table container
.MuiTableContainer-root {
  overflow-x: auto !important;
  overflow-y: auto !important;

  .MuiTable-root {
    border-collapse: separate;
    border-spacing: 0;
  }

  .MuiTableHead-root {
    position: sticky;
    top: 0;
    z-index: 3;
    background: #fff;
  }
}
