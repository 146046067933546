.unit-code {
  width: 150px;
}

.date-col {
  width: 150px;
}

.state-col {
  width: 30px;
}

.region-col {
  width: 150px;
}

.city-col {
  width: 150px
}
