.adj-type-col {
  width: 150px;
}

.action-col {
  width: 150px;
}

.season-col {
  width: 100px;
}

.flag-col {
  width: 100px;
}

.wipe-col {
  width: 50px;
}

.created-col {
  width: 100px;
}

.accordion-cell {
  max-height: 20px;
}