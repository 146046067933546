@import '../../../styles/brand-colors';

.loading {
  padding-top: 200px;
  position: relative;
}

.loader-dots {
  position: absolute;
  top: 50%;
  left: 50%;

  & div {
    background-color: $lake;
    width: 8px;
    height: 8px;
    margin: 0 2px;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 0.8s infinite ease-in-out both;
    animation: sk-bouncedelay 0.8s infinite ease-in-out both;
  }
}

@media screen and (max-width: 768px) {
  .loader-dots {
    margin-top: 10%;
  }
}

.loader-dots {
  .bounce1 {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
    margin-bottom: 0 !important;
  }

  .bounce2 {
    -webkit-animation-delay: -0.15s;
    animation-delay: -0.15s;
    margin-bottom: 0 !important;
  }

  .bounce3 {
    margin-bottom: 0 !important;
  }
}


@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0.8);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

