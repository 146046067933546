@import '../../../styles/brand-colors';

.cohort-col {
  width: 300px;
}

.type-col {
  width: 100px;
}

.email-col {
  width: 200px;
}

.desc-col {
  width: 400px
}

