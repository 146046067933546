.details-input-row {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-end;

  .details-input-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    width: auto;
    margin-bottom: 0; // unset (!important) for modal-module_modalWindow__1kWze but somehow only affected 2/3 input-groups
  }
}

.details-loading {
  padding-top: 300px;
}

.details-cb {
  width: 190px !important;
}

.details-btn {
  width: 150px !important;
}

.details-cohort {
  width: 400px !important;
  .details-cohort-filter {
    min-width: 300px;
  }
}

.details-date {
  width: 170px !important;
  padding-right: 10px;

  .details-date-filter {
    min-width: 120px;
  }
}

.form-footer {
  span {
    display: inline-flex;
  }
}

.no-padding {
  padding: 0 !important;
}

.number-input {
  border: unset !important;
}

.ranges-spacer {
  height: 47px;
}

label {
  margin: 5px 3px
}

.details-form-body {
  height: 605px
}

.pg-label {
  font-size: 13px;
  height: 20px;
  padding-left: 300px;
}

.influence-input {
  width: 55px;
  font-size: 12px;
}

.details-row {
  display: flex;
  flex-direction: row;
}

.details-col {
  display: flex;
  flex-direction: column;
}

.details-matrix-col{
  width: 800px;
}

.details-radio-col {
  width: 300px;
}


.error {
  background-color: lightcoral;
}

.populated {
  background-color: lightgreen;
}

.hh {
  position: fixed;
}

.influence-grid {
  display: grid;
  grid-template: 30px 30px 30px 30px 30px 30px 30px 30px 30px / 110px 75px 75px 75px 75px 75px 75px 75px ;
  grid-gap: 15px;

  .x1_1 {
    grid-row: 1/8;
    grid-column: 1/8
  }

  .x1_1 {
    grid-row: 1/8;
    grid-column: 1/8
  }

  .x1_2 {
    grid-row: 1/8;
    grid-column: 2/8
  }

  .x1_3 {
    grid-row: 1/8;
    grid-column: 3/8
  }

  .x1_4 {
    grid-row: 1/8;
    grid-column: 4/8
  }

  .x1_5 {
    grid-row: 1/8;
    grid-column: 5/8
  }

  .x1_6 {
    grid-row: 1/8;
    grid-column: 6/8
  }

  .x1_7 {
    grid-row: 1/8;
    grid-column: 7/8
  }

  .x1_8 {
    grid-row: 1/8;
    grid-column: 8/8
  }

  .x2_1 {
    grid-row: 2/8;
    grid-column: 1/8
  }

  .x2_2 {
    grid-row: 2/8;
    grid-column: 2/8
  }

  .x2_3 {
    grid-row: 2/8;
    grid-column: 3/8
  }

  .x2_4 {
    grid-row: 2/8;
    grid-column: 4/8
  }

  .x2_5 {
    grid-row: 2/8;
    grid-column: 5/8
  }

  .x2_6 {
    grid-row: 2/8;
    grid-column: 6/8
  }

  .x2_7 {
    grid-row: 2/8;
    grid-column: 7/8
  }

  .x2_8 {
    grid-row: 2/8;
    grid-column: 8/8
  }

  .x3_1 {
    grid-row: 3/8;
    grid-column: 1/8
  }

  .x3_2 {
    grid-row: 3/8;
    grid-column: 2/8
  }

  .x3_3 {
    grid-row: 3/8;
    grid-column: 3/8
  }

  .x3_4 {
    grid-row: 3/8;
    grid-column: 4/8
  }

  .x3_5 {
    grid-row: 3/8;
    grid-column: 5/8
  }

  .x3_6 {
    grid-row: 3/8;
    grid-column: 6/8
  }

  .x3_7 {
    grid-row: 3/8;
    grid-column: 7/8
  }

  .x3_8 {
    grid-row: 3/8;
    grid-column: 8/8
  }

  .x4_1 {
    grid-row: 4/8;
    grid-column: 1/8
  }
  .x4_2 {
    grid-row: 4/8;
    grid-column: 2/8
  }
  .x4_3 {
    grid-row: 4/8;
    grid-column: 3/8
  }
  .x4_4 {
    grid-row: 4/8;
    grid-column: 4/8
  }
  .x4_5 {
    grid-row: 4/8;
    grid-column: 5/8
  }
  .x4_6 {
    grid-row: 4/8;
    grid-column: 6/8
  }
  .x4_7 {
    grid-row: 4/8;
    grid-column: 7/8
  }
  .x4_8 {
    grid-row: 4/8;
    grid-column: 8/8
  }
  .x5_1 {
    grid-row: 5/8;
    grid-column: 1/8
  }
  .x5_2 {
    grid-row: 5/8;
    grid-column: 2/8
  }
  .x5_3 {
    grid-row: 5/8;
    grid-column: 3/8
  }
  .x5_4 {
    grid-row: 5/8;
    grid-column: 4/8
  }
  .x5_5 {
    grid-row: 5/8;
    grid-column: 5/8
  }
  .x5_6 {
    grid-row: 5/8;
    grid-column: 6/8
  }
  .x5_7 {
    grid-row: 5/8;
    grid-column: 7/8
  }
  .x5_8 {
    grid-row: 5/8;
    grid-column: 8/8
  }
  .x6_1 {
    grid-row: 6/8;
    grid-column: 1/8
  }
  .x6_2 {
    grid-row: 6/8;
    grid-column: 2/8
  }
  .x6_3 {
    grid-row: 6/8;
    grid-column: 3/8
  }
  .x6_4 {
    grid-row: 6/8;
    grid-column: 4/8
  }
  .x6_5 {
    grid-row: 6/8;
    grid-column: 5/8
  }
  .x6_6 {
    grid-row: 6/8;
    grid-column: 6/8
  }
  .x6_7 {
    grid-row: 6/8;
    grid-column: 7/8
  }
  .x6_8 {
    grid-row: 6/8;
    grid-column: 8/8
  }
  .x7_1 {
    grid-row: 7/8;
    grid-column: 1/8
  }
  .x7_2 {
    grid-row: 7/8;
    grid-column: 2/8
  }
  .x7_3 {
    grid-row: 7/8;
    grid-column: 3/8
  }
  .x7_4 {
    grid-row: 7/8;
    grid-column: 4/8
  }
  .x7_5 {
    grid-row: 7/8;
    grid-column: 5/8
  }
  .x7_6 {
    grid-row: 7/8;
    grid-column: 6/8
  }
  .x7_7 {
    grid-row: 7/8;
    grid-column: 7/8
  }
  .x7_8 {
    grid-row: 7/8;
    grid-column: 8/8
  }
  .x8_1 {
    grid-row: 8/8;
    grid-column: 1/8
  }
  .x8_2 {
    grid-row: 8/8;
    grid-column: 2/8
  }
  .x8_3 {
    grid-row: 8/8;
    grid-column: 3/8
  }
  .x8_4 {
    grid-row: 8/8;
    grid-column: 4/8
  }
  .x8_5 {
    grid-row: 8/8;
    grid-column: 5/8
  }
  .x8_6 {
    grid-row: 8/8;
    grid-column: 6/8
  }
  .x8_7 {
    grid-row: 8/8;
    grid-column: 7/8
  }
  .x8_8 {
    grid-row: 8/8;
    grid-column: 8/8
  }
  .x8_1 {
    grid-row: 8/8;
    grid-column: 1/8
  }
}