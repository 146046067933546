@import '../../styles/brand-colors';

// Spacing variables
$spacing-xs: 4px;
$spacing-sm: 8px;
$spacing-md: 16px;
$spacing-lg: 24px;
$spacing-xl: 32px;

// Color variables (if not defined in brand-colors)
$brand-primary: #0072EF;  // Vacasa blue
$midnight-90: #1A1A1A;    // Dark text
$midnight-70: #4D4D4D;    // Secondary text
$dusk-thirty: #E6E6E6;    // Border color

.table-utility-row {
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;

  .table-input-group {
    display: flex;
    flex-direction: row;
    width: 15%;

    label {
      padding-right: 4px;
    }
    .table-options {
      width: 200px;
    }
  }
}

.table-list-loading {
  padding-top: 200px;
}

.table-form-row {
  display: flex;
  gap: $spacing-md;
  padding: $spacing-md;
  align-items: flex-start;

  .col {
    flex-shrink: 0;  // Prevent columns from shrinking
  }
}

.tether-table-body {
  .value-input {
    width: 60px;
  }
  .unit-input {
    width: 90px;
  }
  .default-input {
    width: 150px;
  }

  .title-input {
    width: 550px;
  }

  .address-input {
    width: 400px;
  }
  .text-input {
    width: 195px;
  }

  .note-input {
    width: 300px;
  }

  .select-input {
    width: 260px;
  }

  .number-input {
    width: 200px
  }

  .table-auto-complete {
    width: 450px;
  }
}

.table-action-icon {
  cursor: pointer;
}

.date-table {
  padding-top: 15px;
  .date-col {
    width: 125px
  }
  .date-input-col {
    width: 250px;
  }
  .date-action-col {
    width: 100px;
  }
}

.table-body {
  height: 1100px;
}

.dt-form-body {
  height: 575px;
  min-height: 300px;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px;
}

.ut-form-body {
  height: 440px;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px;
}

.ut-form-footer {
  border-top: 1px solid $dusk-thirty;
  padding-top: 12px;

  .form-footer-buttons {
    float: right;
    margin-right: 5px;

    .MuiButtonGroup-root {
      .MuiButton-root {
        margin: 0;  // Remove default button margins
        text-transform: none;
        
        &:not(:last-child) {
          border-right: 1px solid rgba(0, 0, 0, 0.23);
        }
      }
    }
  }
}

.generic-table {
  border: 1px solid $midnight-30;
  border-radius: 4px;
  overflow: hidden;
}

.tether-table {
  .tether-table-header {
    .unit-id-col {
      width: 90px;
    }
    .value-type-col {
      width: 110px;
    }
    .value-col {
      width: 105px;
    }
    .note-col {
      width: 330px;
    }
    .action-col {
      width: 75px;
    }
  }
}

.holiday-form-body {
  height: 580px;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px;
}

.id-col {
  width: 4%;
}
.active-col {
  width: 1%;
}
.title-col {
  width: 13%;
}
.value-col {
  width: 8%;
}
.address-col {
  width: 12%;
}
.email-col {
  width: 7%;
}
.note-col {
  width: 400px
}
.date-range-col {
  width: 10%;
}
.geography-col {
  width: 8%;
}
.actions-col {
  width: 1%;
}

.date-button {
  margin-top: 34px;
}

.geography-row {
  min-height: 50px;

  .label-col {
    width: 200px;
  }
}

.th-underline {
  border-bottom-color: #a0a0a0a0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.cd-border-right {
  border-right-color: #a0a0a0a0;
  border-right-style: solid;
  border-right-width: 1px;
}

.cd-border-left {
  border-left-color: #a0a0a0a0;
  border-left-style: solid;
  border-left-width: 1px;
}

.cd-border-top {
  border-top-color: #a0a0a0a0;
  border-top-style: solid;
  border-top-width: 1px;
}

.form-control-select {
    margin-top: 0 !important;
    
    .MuiInputLabel-root {
        transform: translate(14px, 14px) scale(1) !important;
    }
}

.form-section {
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  padding: $spacing-sm;
  margin-bottom: $spacing-sm;
  
  .form-section-title {
    margin: 0 0 $spacing-sm;
    color: $midnight-90;
    font-weight: 500;
  }

  .checkbox-group {
    display: flex;
    align-items: center;
    gap: $spacing-sm;

    label {
      margin-bottom: 0;
    }
  }
}

.geography-section {
  .geography-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $spacing-md;
    cursor: pointer;
    
    &:hover {
      background: rgba($brand-primary, 0.05);
    }

    .geography-title {
      display: flex;
      align-items: center;
      gap: $spacing-sm;

      h5 {
        margin: 0;
      }
    }

    .geography-summary {
      display: flex;
      align-items: center;
      gap: $spacing-sm;
    }
  }
  
  .geography-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    
    &.visible {
      max-height: 1000px;
    }
  }
  
  .toggle-icon {
    transition: transform 0.2s ease;
  }
}

.action-button {
  display: inline-flex;
  align-items: center;
  gap: $spacing-sm;
  padding: $spacing-sm $spacing-md;
  border-radius: 4px;
  font-weight: 500;
  transition: all 0.2s ease;
  
  svg {
    transition: transform 0.2s ease;
  }
  
  &:hover svg {
    transform: scale(1.1);
  }
}

.table-form-group {
    .MuiTextField-root {
        margin-top: 0;
    }
    
    input.value-input,
    input.text-input {
        width: auto;
    }
}

// Add a specific class for the holiday modal
.holiday-event-modal {
  .table-form-row {
    display: flex;
    gap: $spacing-sm;
    padding: $spacing-sm;
    align-items: center;
  }

  .form-section {
    padding: $spacing-xs;
    margin-bottom: $spacing-xs;
    
    .form-section-title {
      margin: 0 0 $spacing-xs;
      font-size: 16px;
    }
  }

  .checkbox-group {
    display: flex;
    align-items: center;
    gap: $spacing-xs;
    height: 40px;

    span {
      white-space: nowrap;
      font-size: 14px;
      color: $midnight-70;
    }
  }

  .MuiTextField-root, .MuiFormControl-root {
    .MuiOutlinedInput-root {
      min-height: 40px;
    }

    // Fix overlapping labels for both TextField and Select
    .MuiInputLabel-outlined,
    .MuiFormLabel-root {
      transform: translate(14px, 12px) scale(1);

      &.MuiInputLabel-shrink {
        transform: translate(14px, -6px) scale(0.75);
      }
    }

    // Add padding for label space
    .MuiOutlinedInput-root {
      padding-top: 0;
      padding-bottom: 0;

      input {
        padding: 10px 14px;
      }

      // Add specific padding for Select
      &.MuiSelect-root {
        padding: 10px 14px;
      }
    }
  }

  // Adjust Autocomplete specific styles
  .MuiAutocomplete-root {
    .MuiOutlinedInput-root {
      padding: $spacing-xs;
    }

    .MuiInputLabel-outlined {
      transform: translate(14px, 12px) scale(1);

      &.MuiInputLabel-shrink {
        transform: translate(14px, -6px) scale(0.75);
      }
    }
  }

  .multi-select-container {
    .MuiButton-root {
      margin-bottom: $spacing-sm;
      text-transform: none;
      padding: 8px 14px;
      
      &.MuiButton-outlined {
        border-color: rgba(0, 0, 0, 0.23);
      }

      svg {
        margin-left: auto;
        height: 16px;
        width: 16px;
      }
    }

    .selected-items {
      display: flex;
      flex-wrap: wrap;
      gap: $spacing-xs;
      margin-top: $spacing-md;
      min-height: 32px;

      .MuiChip-root {
        margin: 2px;
      }
    }
  }

  // Fix label positioning for outlined inputs
  .MuiFormLabel-root.MuiInputLabel-outlined {
    transform: translate(14px, -6px) scale(0.75);
    background: white;
    padding: 0 4px;
  }

  // Ensure proper spacing for the input content
  .MuiOutlinedInput-root {
    margin-top: $spacing-xs;
  }

  .menu-search {
    position: sticky;
    top: 0;
    background: white;
    padding: $spacing-sm;
    z-index: 1;
  }

  // Style all buttons consistently
  .MuiButton-root {
    text-transform: none;
    
    &.MuiButton-contained {
      box-shadow: none;
      
      &:hover {
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
      }
    }

    // Add spacing for button icons
    .vacasa-icon {
      margin-right: $spacing-sm;
    }
  }

  // Update the form control select styles
  .form-control-select {
    margin-top: 0 !important;

    .MuiInputLabel-root {
      transform: translate(14px, -6px) scale(0.75) !important;
      background: white;
      padding: 0 4px;
    }

    .MuiSelect-root {
      margin-top: $spacing-xs;
    }
  }

  // Remove the duplicate styles
  .MuiFormLabel-root.MuiInputLabel-outlined {
    transform: translate(14px, -6px) scale(0.75);
    background: white;
    padding: 0 4px;
  }

  .MuiOutlinedInput-root {
    margin-top: $spacing-xs;
  }
}