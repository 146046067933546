@import '../../styles/brand-colors';
@import '../../App.scss';

.rebl-utility-row {
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;

  @media (max-width: $laptop-breakpoint) {
    padding: 5px;
  }

  .rebl-input-group {
    display: flex;
    flex-direction: row;
    width: 15%;

    .filter-options {
      width: 200px;
      
      @media (max-width: $laptop-breakpoint) {
        width: 150px;
      }
    }
  }
}

.rebl-search-input {
  width: 40%;
  display: flex;
  flex-direction: row;

  .rebl-search {
    width: 80%
  }
}

.refetch-icon {
  display: flex;
}

.rebl-list-table {
  border: 1px solid $midnight-30;
  border-radius: 4px;
  overflow: hidden;

  @media (max-width: $laptop-breakpoint) {
    .table-cell {
      padding: 5px;
      font-size: 12px;
    }
  }
}

.long-table-cell {
  //overflow: hidden;
  font-size: 12px;
  
  @media (max-width: $laptop-breakpoint) {
    font-size: 10px;
  }
}

.rebl-list-loading {
  border: 1px solid $midnight-30;
  border-radius: 4px;
  min-height: 500px;
}

.rebl-modal-loading {
  height: 500px;
}

.rebl-alert-container {
  width: 15%;
  height: 40px; // components should not shift the page when they have something to display such as an alert
  display: flex;
  padding: 0 20px !important;
}
