@import '../../styles/brand-colors';

.rates-evolution-chart-header {
    display: flex;
    flex-wrap: wrap;
    margin-left: 32px;
    padding-left: 16px;
    margin-bottom: 16px;
}

.rates-evolution-line-chart {
    height: 300px;
}

.rates-evolution-chart-brush {
    .recharts-brush-traveller {
        > rect {
            fill: $dusk-lighter;
        }
    }

    .recharts-brush-slide {
        fill: $midnight-10;
        fill-opacity: 1;
    }
}

.model-checkbox {
    min-width: fit-content;
}

.model-checkbox-box {
    color: $midnight-40 !important;

    &:hover {
        background-color: $midnight-0 !important;
    }

    &.MuiCheckbox-colorSecondary {
        padding-right: 0;
    }

    &.MuiCheckbox-colorSecondary.Mui-checked {
        color: $lake !important;
    }
}

.model-checkbox.disabled {
    opacity: 0.6 !important;
    cursor: not-allowed !important;
    .model-checkbox-box {
        cursor: not-allowed !important;
    }
}

.model-checkbox.disabled::after {
    content: ' |' !important;
}

.rates-evolution-line {
    stroke-linecap: round;
}

.no-data-alert {
    padding-top: 150px;
    padding-left: 800px;
}