@import '../../styles/brand-colors';

.cohort-list-search {
  padding: 3px;
  border-radius: 4px;
  border: 1px solid $dusk-thirty;
}

.utility-row {
  padding: 10px 16px;
  display: flex;
  justify-content: flex-end;

  span {
    //display: flex;
    //display: none;
  }
}

.table-icons {
  display: flex;
  flex-direction: row;

  & svg {
    margin-left: 5px;
    margin-right: 5px;
  }

  .table-icon {
    padding-top: 9px;
  }
}

.cohort-list-table {
  border: 1px solid $midnight-30;
  border-radius: 4px;
  overflow: hidden;
}

.cohort-list-loading {
  border: 1px solid $midnight-30;
  border-radius: 4px;
  min-height: 500px;
}

.action-icon-list {
  padding-right: 25px;
  cursor: pointer;
}

//.add-cohort-img {
//  margin-left: 15px;
//
//  svg {
//    margin-top: 5px;
//  }
//}


.alertmessage-module_messageBox__2juVW {
  margin: 0 !important;
}

a {
  text-decoration: none !important;
}