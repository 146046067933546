@import '../../../styles/brand-colors';

.demand-influence-list {
  padding: 2px 0;
}

.demand-influence-table {
  min-height: 300px;
}

.demand-influence-list-search {
  border-radius: 4px;
  border: 1px solid $dusk-thirty;
}

.di-utility-row {
  padding: 10px 16px;
  display: flex;
  justify-content: flex-start;
}

.table-icons {
  display: flex;
  flex-direction: row;
  width: 15%;

  & svg {
    margin-left: 5px;
    margin-right: 5px;
  }

  .table-icon {
    padding-top: 9px;
  }
  label {
    min-width: 100px;
  }
}

.demand-influence-list-table {
  border: 1px solid $midnight-30;
  border-radius: 4px;
  overflow: hidden;
}

.demand-influence-list-loading {
  border: 1px solid $midnight-30;
  border-radius: 4px;
  min-height: 500px;
}

.demand-influence-icon {
  cursor: pointer;
  margin-left: 15px;

  svg {
    margin-top: 5px;
  }
}

.instructions {
  padding: 20px;
}
.spacer {
  width: 48px;
}


.di-search {
  width: 30%;
}

.di-alert-container {
  width: 45%;
  height: 40px; // components should not shift the page when they have something to display such as an alert
  display: flex;
  padding: 0 20px !important;
}


.demand-factor-table {
  margin-top: 10px;
  border: 1px solid $midnight-30;
  border-radius: 4px;
  min-height: 300px;
}

.alertmessage-module_messageBox__2juVW.alertmessage-module_warning__6j735 {
  padding: 0;
  margin: 0;
}

.filter-cb {
  width: 10%;
  margin-top: 0 !important;;
  padding-top: 0 !important;;
}

.virtualized-table-cell {
  path {
    fill: $dusk-light;
  }

  & .icon-success {
    path {
      fill: $success;
    }
  }

  & .icon-danger {
    path {
      fill: $danger;
    }
  }

  & .icon-warning {
    path {
      fill: $warning;
    }
  }
}
