@import '../../../styles/brand-colors';

.form-header {
  border-bottom: 1px solid $dusk-thirty;
  padding-bottom: 12px;
}

.apr-form-body {
  min-height: 300px;
  max-height: 600px;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px;

  .apr-form-row {
    width: 100%;
    display: flex;
    flex-direction: row;

    .apr-input-group {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 10px;

      .apr-inputs {
        width: 350px;
      }

      .select-format {
        width: 350px;
      }

      .number-input {
        width: 150px
      }
    }
  }
}


.form-footer {
  border-top: 1px solid $dusk-thirty;
  padding-top: 12px;

  .form-footer-buttons {
    float: right;
    margin-right: 5px;

    button {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}

.alert-container {
  min-height: 65px; // components should not shift the page when they have something to display such as an alert
  display: flex;

  .alert-message {
    flex-grow: 1;
    width: 100% !important;
    margin: 24px 24px 0 !important;
  }
}

.approval-rejected-reason {
  width: 450px;
}

.instructions {
  overflow-wrap: break-word;
  word-break: normal;
}

.approval-status-label {
  width: 70px;
  //text-align: right;
  padding-right: 8px;
}

.approval-status {
  display: flex;
}