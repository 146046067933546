@import '../../../styles/brand-colors';

.form-header {
  border-bottom: 1px solid $dusk-thirty;
  padding-bottom: 12px;
}

.wiz-form-body {
  min-height: 300px;
  height: 500px;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px;
}

.form-row {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.form-col {
  width: 400px;
  display: flex;
  flex-direction: column;
}

.col-1 {
  width: 450px;
}

.col-2 {
  width: 250px;
}

.input-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 10px;

  .inputs {
    width: 350px;
  }

  .select-format {
    width: 350px;
  }

  .number-input {
    width: 150px
  }
}

.inline-checkboxes {
  display: flex;
  width: 450px;
  padding-top: 10px;
}

.form-footer {
  border-top: 1px solid $dusk-thirty;
  padding-top: 12px;

  .form-footer-buttons {
    float: right;
  }
}

.alert-container {
  min-height: 65px; // components should not shift the page when they have something to display such as an alert
  display: flex;

  .alert-message {
    flex-grow: 1;
    width: 100% !important;
    margin: 24px 24px 0 !important;
  }
}

.span {
  width: 100% !important;
}
.date-selector {
  padding: 0 20px;
}

textarea {
  width: 500px;
}