@import 'styles/brand-colors';
@import 'styles/font-styles';
@import 'App.scss';

body {
  background-color: $midnight-10;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiContainer-root {
  padding: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.pointer {
  &:hover {
    cursor: pointer;
  }
}
.pointer-disabled {
  pointer-events: none;
  opacity: 0.2;
}

.as-link {
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.check-icon {
  fill: $success;
  stroke: $success;
}

.x-icon {
  fill: $danger;
  stroke: $danger;
}

button {
  max-height: 50px
}

// Date-picker modals show up behind the popup without this
.modal-module_modalWindow__1kWze {
  z-index: 1 !important;
}

.modal-module_modalWindow__1kWze div:not(:last-of-type) {
  margin-bottom: unset !important;
}

.modal-module_modalClose__pvct8 {
  z-index: 99999;
}

.modal-module_modalWindow__1kWze > div.modal-module_large__GBNGN {
  padding-top: 25px;
  padding-bottom: 20px;
  width: 1200px !important;
  height: 700px !important;

  @media (max-width: $laptop-breakpoint) {
    .modal-module_modalWindow__1kWze {
      height: calc(100vh - 10px) !important;
      max-height: 700px !important;
    }
  }
}
