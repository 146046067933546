@import '../../styles/brand-colors';

.unit-utility-row {
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;

  .unit-input-group {
    display: flex;
    flex-direction: row;
    width: 15%;

    .filter-options {
      width: 200px;
    }
  }
}

.unit-search-input {
  width: 40%;
  display: flex;
  flex-direction: row;

  .unit-search {
    width: 80%
  }
}

.unit-list-table {
  border: 1px solid $midnight-30;
  border-radius: 4px;
  overflow: hidden;
}

.unit-list-loading {
  border: 1px solid $midnight-30;
  border-radius: 4px;
  min-height: 500px;
}

.unit-alert-container {
  width: 15%;
  height: 40px; // components should not shift the page when they have something to display such as an alert
  display: flex;
  padding: 0 20px !important;
}

.nav-panel {
  position: absolute;
  background-color: $white;
  border-left: 1px solid $dusk-thirty;
  border-right: 1px solid $dusk-thirty;
  box-shadow: -2px 1px 3px $dusk-thirty;
  border-top: 1px solid $dusk-thirty;
  border-radius: 0 3px 0 0;
  height: 150px;
  width: 145px;
  z-index: 300;
}